// first block
export const aboutUs = `«Саша шукає відповіді» – всеукраїнський урок-опитування. Його
учасниці та учасники отримають знання про права та можливості дівчат у сучасному світі. А також - виграють подарунки!`

export const aboutUsSecondPart = `Цьогорічний урок-опитування від Саші вже доступний 
Пройдіть його в чат-боті та виграйте подарунок!`

// second block
export const aboutCompetitionFirstPart = `Саша шукає відповіді» – це щорічний урок-опитування 
у форматі чат-бота, в якому школярі дізнаються про права та можливості дівчат у сучасному світі. Щороку 
з 8 березня по 8 травня у чат-боті буде опубліковано новий урок-опитування, його учасники дізнаютимуться про актуальні 
події в Україні та світі. Та що разу виграватимуть нові подарунки від соціально-відповідальних українських брендів, 
які підтримують гендерну рівність та рівні можливості дівчат та хлопців.`

export const aboutCompetitionSecondPart = `Чат-бот «Саша шукає відповіді» створила 
агенція соціальних змін nudge задля того, щоб молоде покоління дівчат та хлопців завчасно дізнавались 
про ті виклики, які чекають їх попереду та мали змогу протидіяти їм.`

export const aboutCompetitionThirdPart = `Взяти участь в уроці-опитуванні можуть як 
школярі самостійно, так і за ініціативи учителів. Зокрема для цього ми створили план уроку, який можна використати 
для цього. Учителі, що проведуть урок у своїй школі – отримають відзнаку від нашої команди!`

// third block
export const participationInTheCompetitionFirstStep = `Перейдіть у діалог 
  із Сашею в Telegram`

export const participationInTheCompetitionSecondStep = `Пройдіть опитування від неї до кінця. 
  Саша повідомить вам, коли усі ваші відповіді буде прийнято.`

export const participationInTheCompetitionThirdStep = `Після проходження усіх запитань 
ви автоматично станете учасником/учасницею розіграшу подарунків 
від Саші. Результати конкурсу 
ви дізнаєтесь дізнаєтесь 8 травня: 
у чат-боті вам надійде персональне повідомлення. Якщо будуть зміни, ми повідомимо вам там же!`

export const aboutSashaTitle = `ЩО ОТРИМАЮТЬ ТІ, ХТО ВІЗЬМЕ УЧАСТЬ В УРОЦІ- ОПИТУВАННІ ВІД САШІ?`

export const aboutSashaFirstPart = `Знання, аби стати прогресивними та успішними, 
та підтримати своїх подруг.`

export const aboutSashaSecondPart = `Сучасний світ складний: поряд із унікальинми 
можливостями, які дають нові технології та відкриття, 
все ще існують упередження до дівчат та хлопців.`

export const aboutSashaThirdPart = `Ти не можеш те, ти не можеш се... А тут хлопець
 впорається краще за дівчину, а тут ми йому заплатимо більше, але утримувати сім’ю – його відповідальність 
і плювати, що дівчина може заробляти чималенькі кошти самостійно. Чи можна щось з цим зробити? Наша відповідь однозначна – так!`

export const aboutSashaFourthPart = `Сучасна молодь, хлопці та дівчата, зможуть будувати 
рівноправне суспільство, якщо будуть протидіяти несправедливому ставленню до себе. Саме цьому ми навчаємо у чат-боті 
«Саша шукає відповіді».`

export const aboutSashaFifthPart = `А ще, даруємо подарунки тим, хто пройде урок в чат- боті до кінця!`

export const textForm = `Заповніть форму, і ми надішлемо вам план уроку:`

export const textFormFourth = `Скачати без реєстрації.`;

export const textFormSecond = `Якщо ви проведете урок у вашій школі 
з використанням чат-бота «Саша шукає відповіді», то гарантовано отримаєте від нас сертифікат та візнаку за участь в
 розвитку молодого покоління інноваційними методами навчання.`

export const textFormThird = `Детальніше – у плані уроку, який ви отримаєте на пошту 
після заповнення форми.`

export const mapText = `— мапа України, на якій ви можете побачити географію учасниць та 
  учасників конкурсу. Зокрема – шкіл, які взяли участь.`
